import React, { useState } from 'react';
import { GoSettings } from 'react-icons/go';
import { connect } from 'react-redux';
import { updateBackgroundOptions } from '../../store/action-creators/creators';
import './options.scss';

const mapState = state => ({
  options: state.background.options
})

const mapDispatch = dispatch => ({
  changeOption: (name, value) => dispatch(updateBackgroundOptions(name, value))
})

const OptionsConnector = ({ options, changeOption }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);

  return (
    <div className={`options-container ${optionsOpen ? 'active' : ''}`}>
      <OptionsIcon onClick={() => setOptionsOpen(!optionsOpen)} />
      <OptionsMenu open={optionsOpen} 
        initialOptions={options}
        onOptionChange={changeOption} />
    </div>
  )
}

const OptionsIcon = ({ onClick }) => {
  return (
    <GoSettings className='options-icon' onClick={onClick} />
  )
}

const OptionSlider = ({ min, max, step, value, name, label, description, onChange, onMouseUp }) => {
  const endTrackSize = (1 - ((value - min) / (max - min))) * 100;

  return (
    <>
      <label className='slider-label' htmlFor={name}>
        {label} <span className='slider-value'>{value}</span>
      </label>
      <div className='range-container'>
        <span className='track'>
          <span className='track-end'
            style={{ width: `calc(${endTrackSize}% - 2px)`}}></span>
        </span>
        <input type='range' 
          min={min}
          max={max}
          step={step}
          value={value}
          name={name}
          onChange={onChange}
          onMouseUp={onMouseUp} />
      </div>
      <span className='description'>{description}</span>
    </>
  )
}

const OptionsMenu = ({ open, initialOptions, onOptionChange }) => {
  const [options, setOptions] = useState({ 
    sway: initialOptions.sway,
    widthNodes: initialOptions.widthNodes
  });

  const handleOptionChange = event => {
    setOptions({
      ...options,
      [event.target.name]: event.target.value
    });
  }

  const handleSliderBlur = event => {
    onOptionChange(event.target.name, event.target.value);
  }

  return (
    <div className='options-menu depth-2'>
      <div className='options-content'>
        <OptionSlider type='range' 
          min={0}
          max={1}
          step={.05}
          value={options.sway}
          name='sway'
          label='Sway'
          description="Distance (percentage) that this point can travel from its initial point on the grid"
          onChange={handleOptionChange}
          onMouseUp={handleSliderBlur} />
        <OptionSlider type='range' 
          min={5}
          max={50}
          step={1}
          value={options.widthNodes}
          name='widthNodes'
          label='Width Nodes'
          description="Number of points used on the horizontal axis as start or end positions for each polygon"
          onChange={handleOptionChange}
          onMouseUp={handleSliderBlur} />
      </div>
    </div>
  )
}

const Options = connect(mapState, mapDispatch)(OptionsConnector);

export default Options;