export const generateOffsets = count => {
  let numbers = [];
  
  while(numbers.length < count) {
    let match = null;
    
    while(match === null) {
      let random = Math.floor(Math.random() * count);

      if(!numbers.includes(random)) {
        match = random;
      }
    }

    numbers.push(match);
  }

  return numbers;
}

export const Navigation = {
  getTransitionDuration: (lastPath, currentPath) => {
    if(lastPath === '/photography' && currentPath === '/') {
      return 800;
    }

    return 1000;
  }
}

export const PhotoHelper = {
  buildPhotoMapPromise: (photoMap, albumInfo, state, setState) => {
    if(albumInfo && photoMap[albumInfo.id] && state.initialLoad) {
      Promise.all(photoMap[albumInfo.id].map(photo => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            resolve();
          }
          img.src = photo.url;
          setTimeout(() => resolve(), 3000);
        })
      })).then(result => {
        setState({ ...state, initialLoad: false, animating: true });
      });
    }
  }
}

export const Util = {
  chunkArray: (array, chunkSize) => {
    return array.reduce((result, current, index) => {
      if(index === 1) {
        result = [[result]];
      }
      const chunkIndex = Math.floor(index / chunkSize);

      if(!result[chunkIndex]) {
        result[chunkIndex] = [];
      }

      result[chunkIndex].push(current);

      return result;
    });
  }
}