import React, { useEffect } from 'react';
import { ScrambledH1, ScrambledH2 } from '../../shared/scrambled-header/scrambled-header';
import { FaReact, FaGithub } from 'react-icons/fa';
import { DiDotnet } from 'react-icons/di';
import { SiRedux, SiNodeDotJs, SiKubernetes, SiDocker } from 'react-icons/si';
import { BsBriefcaseFill, BsChevronLeft } from 'react-icons/bs';
import { HiMail } from 'react-icons/hi';
import { toggleHeader, hideHeader } from '../../../store/action-creators/creators';
import './about.scss';
import { connect } from 'react-redux';

const mapDispatch = dispatch => ({
  toggleHeader: () => dispatch(toggleHeader()),
  hideHeader: () => dispatch(hideHeader())
})

const AboutConnector = ({ toggleHeader, hideHeader, history }) => {
  useEffect(() => {
    hideHeader();

    return () => toggleHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const years = new Date().getFullYear() - 2015;

  return (
    <section className='about'>
      <button className='back' onClick={() => history.goBack()}><BsChevronLeft /> back</button>
      <div className='about-inner'>
        <img className='profile-picture' src='me.jpg' alt='andrew possardt headshot' />
        <div className='about-content'>
          <div className='title-description-container'>
            <ScrambledH1 className='depth-2' text='andrew possardt' startDelay={1500} />
            <ScrambledH2 className='depth-2' text='web developer' startDelay={1500} />
            <p className='depth-2'>
              thanks for checking out my site. i'm a full-stack web developer specializing in ReactJS and the .NET ecosystem. i've been developing web applications for {years} years. in my free time, I enjoy traveling and photography. 
            </p>
          </div>
          <div className='tech-container'>
            <ul className='tech-list'>
              <li className='tech-item dotnet'><DiDotnet /> .net core</li>
              <li className='tech-item react'><FaReact /> reactjs</li>
              <li className='tech-item redux'><SiRedux /> redux</li>
              <li className='tech-item node'><SiNodeDotJs /> nodejs</li>
              <li className='tech-item docker'><SiDocker /> docker</li>
              <li className='tech-item k8s'><SiKubernetes /> kubernetes</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='button-container'>
        <a className='big-button github' rel='noreferrer' target='_blank' href='https://github.com/ampossardt'>
          <span className='button-text'><FaGithub />view my code on github</span>
        </a>
        <a className='big-button email' rel='noreferrer' target='_blank' href='/Resume-6.9.docx'>
          <span className='button-text'><BsBriefcaseFill/>download my resume</span>
        </a>
        <a className='big-button portfolio' rel='noreferrer' target='_blank' href='mailto:andrewmpossardt@gmail.com'>
          <span className='button-text'><HiMail />send me an email</span>
        </a>
      </div>
    </section>
  )
}

const About = connect(null, mapDispatch)(AboutConnector);

export default About;