import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setSelectedPhoto, toggleHeader } from '../../../store/action-creators/creators';
import AlbumList from './albums/albums';
import './photography.scss';
import PhotoDetail from './photos/photo-detail';
import PhotoGrid from './photos/photo-grid';

const mapState = state => ({
  albums: state.photography.albums,
  selectedPhoto: state.photography.selectedPhoto
})

const mapDispatch = dispatch => ({
  setSelectedPhoto: selected => dispatch(setSelectedPhoto(selected)),
  toggleHeader: () => dispatch(toggleHeader())
})

const PhotographyConnector = ({ albums, selectedPhoto, setSelectedPhoto, toggleHeader }) => {
  const [state, setState] = useState({
    activeAlbum: null,
    transitionGrid: false
  })

  // Transition grid called first, then onAlbumClick. Both need to be passed down separately
  // so that we can transition the grid, disable the mouseout handle (in the album list)
  // and then actually click the album
  const startGridTransition = () => {
    setState(state => ({ ...state, transitionGrid: true }));
  }

  const onAlbumClick = albumInfo => {
    setTimeout(() => {
      setState(state => ({ ...state, transitionGrid: false, activeAlbum: albumInfo }));
    }, 400);
  }
  

  const onPhotoClick = photoInfo => {
    setSelectedPhoto(photoInfo);
    toggleHeader();
  }

  return (
    <div className={`photography ${selectedPhoto ? 'photo-selected' : ''}`}>
      {
        albums ? (
          <AlbumList albums={albums} 
            onClick={onAlbumClick}
            startGridTransition={startGridTransition}
            activeAlbum={state.activeAlbum} />
        ) : null
      }
      {
        albums ? albums.map(album => (
          state.activeAlbum && album.id === state.activeAlbum.id ? (
            <PhotoGrid key={album.id} 
              albumInfo={state.activeAlbum} 
              transition={state.transitionGrid}
              onClick={onPhotoClick} />
          ) : null
        )) : null
      }
      <PhotoDetail selectedPhoto={selectedPhoto} onClose={() => setSelectedPhoto(null)} />
    </div>
  );
}

const Photography = connect(mapState, mapDispatch)(PhotographyConnector);

export default Photography;