import React from 'react';

const BoxOutline = () => (
  <>
    <span className="line bottom"></span>
    <span className="line right"></span>
    <span className="line top"></span>
    <span className="line left"></span>
  </>
)

export default BoxOutline;