export const getAlbums = 'photos/albums/get';
export const getAlbumsSuccess = 'photos/albums/get/success';
export const getPhotos = 'photos/get';
export const getPhotosSuccess = 'photos/get/success';
export const toggleBackground = 'background/hide';
export const updatePhotoMap = 'photos/update';
export const toggleDrawer = 'drawer/toggle';
export const changeBackgroundOption = 'background/options/update';
export const setSelectedPhoto = 'photos/photo/select';
export const updateBackgroundData = 'background/data/update';
export const toggleHeader = 'header/toggle';
export const hideHeader = 'header/hide';