import React from 'react';
import { connect } from 'react-redux';
import Options from '../background/options';
import { ScrambledH1 } from '../shared/scrambled-header/scrambled-header';
import HeaderNavigation from './header-navigation';
import './header.scss';

const mapState = state => ({
  hide: state.header.hide
})

const HeaderConnector = ({ hide }) => {
  return (
    <header className={`animate-entry-delayed ${hide ? 'hide' : ''}`}>
      <ScrambledH1 text='andrew possardt' startDelay={1500} />
      <HeaderNavigation />
      <Options />
    </header>
  )
}

const Header = connect(mapState)(HeaderConnector);

export default Header;