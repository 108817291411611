import React from 'react';
import './background.scss';
import PolygonContainer from '../shared/polygon-container/polygon-container';

const Background = () => {
  return (
    <div className='background-container'>
      <div className={`background`}></div>
      <PolygonContainer className={`poly-container-main`} />
    </div>
  )
}

export default Background;