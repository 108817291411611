import React, { useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import './projects.scss';
import BoxOutline from '../../shared/box-outline';
import { IconMap } from '../../../tools/icon-helper';
import { CgChevronDoubleRight } from 'react-icons/cg';
import { connect } from 'react-redux';

const LinkButton = ({ data }) => {
  const { icon, label, url, color = 'purple' } = data;
  const Icon = IconMap[icon];

  return (
    <a href={url} target='_blank' className={`link-button ${color}`} rel='noreferrer'>
      <span className='label'>
        <Icon className='type-icon' />
        { label }
        <CgChevronDoubleRight className='arrow-icon' />
      </span>
    </a>
  )
}

const Project = ({ data }) => {
  const { title, summary, photo, links } = data;
  const [state, setState] = useState({
    x: 0,
    y: 0
  });

  const debounceMouse = useRef(throttle(event => updateMouseMovement(event), 100)).current;
  
  const onMouseMove = event => {
    debounceMouse(event);
  }

  const updateMouseMovement = event => {
    if(!event.target.classList.contains('project')) return;
    const offset = event.target.getBoundingClientRect();
    const width = event.target.clientWidth;
    const height = event.target.clientHeight;
    const x = (event.pageX - offset.left) - (width / 2);
    const y = (event.pageY - offset.top) - (height / 2);
    const delta = 8;

    setState({
      x: x / (width / 2) * -delta,
      y: y / (height / 2) * -delta
    })
  }

  const onMouseOut = () => {
    setTimeout(() => {
      setState({ x: 0, y: 0 });
    }, 300)
  }

  return (
    <div className='project'
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}>
      <div className='project-inner depth-3'
        style={{ transform: `translate(${state.x}px, ${state.y}px)`}}>
        <figure className='project-image'
          style={{ backgroundImage: `url('${photo}')`}}>
        </figure>
        <div className='title-container'>
          <h2>{ title }</h2>
        </div>
        <div className='content-container'>
          <p>{ summary }</p>
        </div>
        <div className='link-container'>
          {
            links.map(link => (
              <LinkButton key={link.id} data={link} />
            ))
          }
        </div>
        <BoxOutline />
      </div>
    </div>
  )
}

const mapState = state => ({
  projects: state.projects
})

const ProjectsConnector = ({ projects }) => {
  return (
    <section className='projects'>
      {
        projects.map(project => (
          <Project key={project.id} data={project} />
        ))
      }
    </section>
  )
}

const Projects = connect(mapState)(ProjectsConnector)

export default Projects;