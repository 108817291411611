import * as Types from '../actions/actions';

export const getPhotos = albumId => {
  return dispatch => {
    fetch(`http://localhost:3500/photos?id=${albumId}`)
      .then(response => {
        if(response.ok) {
          return response.json();
        }

        throw response.statusText;
      })
      .then(data => {
        dispatch({ type: Types.getPhotosSuccess, payload: data.photos })
      }).catch(error => console.error(error));
  }
}

export const getAlbums = () => {
  return dispatch => {
    fetch('/.netlify/functions/albums')
      .then(response => response.json())
      .then(data => {
        dispatch({ type: Types.getAlbumsSuccess, payload: data })
      })
  }
}

export const toggleBackground = () => {
  return { type: Types.toggleBackground };
}

export const updatePhotoMap = (id, photos) => {
  return { type: Types.updatePhotoMap, payload: { id, photos } };
}

export const getPhotoset = (id, page) => {
  return dispatch => {
    fetch(`/.netlify/functions/photos?id=${id}&page=${page}`)
      .then(response => {
        if(response.ok) {
          return response.json();
        }
      })
      .then(data => {
        dispatch(updatePhotoMap(id, data));
      }).catch(error => console.error(error));
  }
}

export const updateBackgroundOptions = (name, value) => {
  return { type: Types.changeBackgroundOption, payload: { name, value } }
}

export const updateBackgroundData = data => {
  return { type: Types.updateBackgroundData, payload: data }
}

export const setSelectedPhoto = photo => {
  return { type: Types.setSelectedPhoto, payload: photo }
}

export const toggleHeader = () => {
  return { type: Types.toggleHeader };
}

export const hideHeader = () => {
  return { type: Types.hideHeader };
}