import React, { useLayoutEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import { getPhotoset } from '../../../../store/action-creators/creators';
import { PhotoHelper, Util } from '../../../../tools/tools';
import LoadingBar from '../../../shared/loading-bar';
import './photo-grid.scss';

const mapState = state => ({
  photoMap: state.photography.photoMap
})

const mapDispatch = dispatch => ({
  getPhotos: (id, page) => dispatch(getPhotoset(id, page))
})

const HighlightLines = () => {
  return (
    <>
      <span className='underline first'></span>
      <span className='underline last'></span>
    </>
  )
}

const GridContainer = ({ chunk, onClick }) => {
  const newChunk = chunk.slice(3, 6);

  if(!newChunk.length) return null;

  return (
    <div className='grid-container'>
      {
        newChunk.map((photo, index) => (
          <figure key={photo.id} className='photo' onClick={() => onClick(photo)}>
            <img src={photo.url}
              className={index % 2 === 1 ? 'flip-animation' : ''}
              alt='' />
            <div className={`cover ${index % 2 === 1 ? 'flip-animation' : ''}`}>
            </div>
            <HighlightLines />
          </figure>
        ))
      }
    </div>
  )
}

const FeaturedContainer = ({ chunk, featuredFirst, onClick }) => {
  const newChunk = featuredFirst ? chunk.slice(0, 3) : chunk.slice(6, 9);
  const getFeaturedClass = index => {
    if(featuredFirst && index % 3 === 0) {
      return 'featured';
    } else if (!featuredFirst && index % 3 === 2) {
      return 'featured right';
    }

    return '';
  }

  if(!newChunk.length) return null;

  return (
    <div className='grid-container featured-container'>
      {
        newChunk.map((photo, index) => (
          <figure key={photo.id} className={`photo ${getFeaturedClass(index)}`} onClick={() => onClick(photo)}>
            <img src={photo.url}
              className={index % 2 === 1 ? 'flip-animation' : ''}
              alt='' />
            <div className={`cover ${index % 2 === 1 ? 'flip-animation' : ''}`}>
            </div>
            <HighlightLines />
          </figure>
        ))
      }
    </div>
  )
}

const PhotoGridConnector = ({ albumInfo, photoMap, transition, getPhotos, onClick }) => {
  const [state, setState] = useState({
    initialLoad: true,
    animating: false,
    page: 1
  });

  const totalPhotos = useRef(albumInfo.photoCount).current;

  useLayoutEffect(() => {
    if(albumInfo && !photoMap[albumInfo.id]) {
      getPhotos(albumInfo.id, state.page);
      setState({...state, page: state.page + 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  PhotoHelper.buildPhotoMapPromise(photoMap, albumInfo, state, setState);
  
  const handleScrollDebounce = useRef(throttle(event => handleScroll(event), 500)).current;
  const onScroll = event => {
    handleScrollDebounce(event);
  }

  const handleScroll = event => {
    const position = event.target.scrollTop + event.target.clientHeight;
    const targetScrollHeight = event.target.scrollHeight * .75;

    if(position > targetScrollHeight) {
      setState(state => {
        if((state.page - 1) * 9 < totalPhotos) {
          getPhotos(albumInfo.id, state.page);
        
          return { ...state, page: state.page + 1 };
        }

        return state;
      });
    }
  }

  return (
    (state.initialLoad || state.animating) ? (
      <LoadingBar contentLoaded={state.animating}
        onAnimationFinish={() => setState({ ...state, animating: false })} />
    ) : (
      <div className={`photo-grid ${transition ? 'transitioning' : ''}`}
        onScroll={onScroll}>
        {
          Util.chunkArray(photoMap[albumInfo.id].slice(), 9).map(chunk => (
            <>
              <FeaturedContainer chunk={chunk} featuredFirst={true} onClick={onClick} />
              <GridContainer chunk={chunk}  onClick={onClick} />
              <FeaturedContainer chunk={chunk} featuredFirst={false} onClick={onClick} />
            </>
          ))
        }
      </div>
    )
  )
}

const PhotoGrid = connect(mapState, mapDispatch)(PhotoGridConnector);

export default PhotoGrid;