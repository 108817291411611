import { createReducer } from '@reduxjs/toolkit';
import * as Types from '../actions/actions';
import { nanoid } from '@reduxjs/toolkit'

console.log(window.location.pathname === '/about');

const initialState = {
  hideBackground: true,
  background: {
    data: null,
    options: {
      sway: .6,
      widthNodes: Math.ceil(window.innerWidth / (1920 / 24))
    }
  },
  header: {
    hide: window.location.pathname === '/about'
  },
  projects: [
    {
      id: nanoid(),
      title: 'artisan wants generator',
      summary: 'generate a static HTML page hosted on GitHub for trading artisan keycaps',
      photo: '/artisan-wants.jpg',
      content: [
        "In the mechanical keyboard community, there are custom-made keycaps made in small quantities by artists known as artisans. These 'artisan keycaps' are coveted for many different reasons - the way the sculpt looks, the color of resin in which they were cast, or simply due to their rarity.",
        "The primary means of trading caps with community members is via a 'classifieds-style' subreddit called 'mechmarket'. Users put up timestamped pictures of the keycaps they would like to trade, requesting either PayPal as a means of payment, or suggest other caps that they are interested in. For simplicity, many users make a Google Docs document to list the particular caps, sculpts, and colorways they are looking for. Unfortunately, many are plain looking and aren't mobile-friendly.",
        "The artisan wants generator looks to solve that by providing a way to build a 'wants list' via a WYSIWYG editor, then generating static HTML and CSS files which are uploaded to GitHub to be hosted on GitHub Pages. Users select content, colors, and finally upload the content, pushing the generated files to the default GitHub pages public repository for their account."
      ],
      links: [
        { id: nanoid(), url: 'https://github.com/ampossardt/artisan-trade-list-generator', icon: 'github', label: 'github', color: 'purple' },
        { id: nanoid(), url: 'https://artisan-wants-client.herokuapp.com/', icon: 'website', label: 'website', color: 'green' }
      ],
      badges: ['react', 'redux', 'nodejs', 'docker', 'heroku'],
      website: 'https://artisan-wants-client.herokuapp.com/',
      code: 'https://github.com/ampossardt/artisan-trade-list-generator'
    },
    {
      id: nanoid(),
      title: 'propoint data collection',
      summary: 'mobile-first web app for speech therapy data collection',
      photo: '/propoint.png',
      links: [
        { id: nanoid(), url: 'https://propointdatacollection.com', icon: 'website', label: 'website', color: 'green' }
      ]
    }
  ],
  photography: {
    photoMap: {},
    albums: null,
    selectedPhoto: null
  }
}

const defaultReducer = createReducer(initialState, {
  [Types.getPhotosSuccess]: (state, action) => {
    state.photography.data = action.payload;
  },
  [Types.getAlbumsSuccess]: (state, action) => {
    state.photography.albums = action.payload;
  },
  [Types.toggleBackground]: (state, action) => {
    state.hideBackground = !state.hideBackground;
  },
  [Types.updatePhotoMap]: (state, action) => {
    const photoMap = state.photography.photoMap[action.payload.id];
    if(!photoMap) {
      state.photography.photoMap[action.payload.id] = action.payload.photos;
    } else if(action.payload.photos.length) {
      state.photography.photoMap[action.payload.id].push(...action.payload.photos);
    }
  },
  [Types.changeBackgroundOption]: (state, action) => {
    state.background.options[action.payload.name] = action.payload.value;
  },
  [Types.setSelectedPhoto]: (state, action) => {
    state.photography.selectedPhoto = action.payload;
  },
  [Types.updateBackgroundData]: (state, action) => {
    state.background.data = action.payload;
  },
  [Types.toggleHeader]: (state, action) => {
    state.header.hide = !state.header.hide;
  },
  [Types.hideHeader]: (state, action) => {
    state.header.hide = true;
  }
})

export default defaultReducer;