
import { useState, useEffect } from 'react';

export function useLetterScramble(target, iterationTime = 100, startDelay = 1000) {
  const charMap = 'abcdefghijklmnopqrstuvwxyz'.split('');
  const getRandom = () => Math.floor(Math.random() * 26);

  const [state, setState] = useState({
    name: target.split('').map(char => charMap[getRandom()]),
    lettersFrozen: 0,
    beginUnscramble: false
  });

  const registerNameGeneration = () => {
    setTimeout(() => setState(state => ({ ...state, beginUnscramble: true })), startDelay);
    
    return setInterval(() => {
      setState(state => {
        const targetArray = target.split('');

        for(let i = state.lettersFrozen; i < (15 - state.lettersFrozen); i++) {
          targetArray[i] = charMap[getRandom()];
        }

        return { 
          name: targetArray.join(''), 
          lettersFrozen: state.beginUnscramble ? state.lettersFrozen + 1 : state.lettersFrozen,
          beginUnscramble: state.beginUnscramble
        };
      })
    }, iterationTime);
  }

  const deregisterNameGeneration = (intervalId) => {
    setTimeout(() => clearInterval(intervalId), iterationTime * target.length + startDelay);
  }
  
  useEffect(() => {
    const id = registerNameGeneration();

    deregisterNameGeneration(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return state.name;
}