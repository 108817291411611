import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Photography from '../pages/photography/photography';
import Projects from '../pages/projects/projects';
import About from '../pages/about/about';

const TransitionRouter = ({ location }) => {
  return (
    <TransitionGroup className='transition-container animate-entry-delayed'> 
      <CSSTransition classNames='transition' key={location.key} timeout={1000}>
        <Switch location={location}>
          <Redirect from='/' to='/projects' exact />
          <Route path='/projects' component={Projects} exact />
          <Route path='/photography' component={Photography} exact />
          <Route path='/about' component={About} exact />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default withRouter(TransitionRouter);