import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './App.scss';
import Background from './components/background/background';
import Header from './components/header/header';
import TransitionRouter from './components/router';
import { getAlbums } from './store/action-creators/creators';

const mapDispatch = dispatch => ({
  getAlbums: () => dispatch(getAlbums())
})

const AppConnector = ({ getAlbums }) => {
  useEffect(() => {
    // Preload albums for photography page
    getAlbums();
  }, [getAlbums]);

  return (
    <>
      <Background />
      <Header />
      <TransitionRouter />
    </>
  );
}

const App = connect(null, mapDispatch)(AppConnector);
export default withRouter(App);