import React, { useEffect, useState } from 'react';
import { ImSpinner2 } from 'react-icons/im';
import { AiOutlineClose } from 'react-icons/ai';
import './photo-detail.scss';
import { ExifIconMap } from '../../../../tools/icon-helper';
import { useDispatch } from 'react-redux';
import { toggleHeader } from '../../../../store/action-creators/creators';

const CloseDetail = ({ onClose }) => {
  return (
    <div className='close'
      onClick={onClose}>
      <AiOutlineClose />
    </div>
  )
}

const ExifItem = ({ dataItem }) => {
  const Icon = ExifIconMap[dataItem.tag];

  return (
    <li>
      <span className='icon'>
        <Icon />
      </span>
      <span className='value'>{dataItem.value}</span>
    </li>
  )
}

const PhotoExif = ({ exifData }) => {
  return (
    <figure className='exif-data'>
      <ul>
        {
          exifData.map(item => (
            <ExifItem key={item.tag} dataItem={item} />
          ))
        }
      </ul>
    </figure>
  )
}

const PhotoDetail = ({ selectedPhoto, onClose }) => {
  const [state, setState] = useState({
    activePhoto: null,
    loaded: false
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if(selectedPhoto) {
      fetch(`/.netlify/functions/photo?id=${selectedPhoto.id}&secret=${selectedPhoto.secret}&server=${selectedPhoto.server}&farm=${selectedPhoto.farm}`)
      .then(response => {
        if(response.ok) {
          return response.json();
        }
      })
      .then(data => {
        setState(state => ({ ...state, activePhoto: data }));

        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            resolve();
          }
          img.src = data.url;
          setTimeout(() => resolve(), 3000);
        })
      })
      .then(() => {
        setState(state => ({ ...state, loaded: true }))
      })
      .catch(error => console.error(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhoto]);

  const handleClose = () => {
    onClose();
    dispatch(toggleHeader());
    setTimeout(() => setState({ activePhoto: null, loaded: false }), 500);
  }

  return (
    <div className='photo-detail'>
      {
        state.loaded && state.activePhoto ? (
          <div className='image-container animate-entry'>
            <img src={state.activePhoto.url} alt='' />
            <PhotoExif exifData={state.activePhoto.exif} />
            <CloseDetail onClose={handleClose} />
          </div>
        ) : (
          <ImSpinner2 className='loading' />
        )
      }
    </div>
  )
}

export default PhotoDetail;