import { FaGithubSquare } from 'react-icons/fa';
import { FiCamera, FiClock } from 'react-icons/fi';
import { MdWebAsset } from 'react-icons/md';
import { RiCameraLensLine } from 'react-icons/ri';

export const IconMap = {
  'github': FaGithubSquare,
  'website': MdWebAsset
}

export const ExifIconMap = {
  'Camera': FiCamera,
  'ExposureTime': FiClock,
  'FNumber': RiCameraLensLine,
  'ISO': () => {
    return (
      <span className='iso-icon'>ISO</span>
    )
  }
}