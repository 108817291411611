import React, { useState } from 'react';
import { IoCaretDown, IoEllipsisVertical } from 'react-icons/io5';
import { withRouter } from 'react-router-dom';
import { Routes } from '../../tools/constants';
import './header-navigation.scss';

const MobileNavigationIcon = ({ open, onClick }) => {
  return (
    <IoEllipsisVertical className={`mobile-menu-icon ${ open ? 'open' : '' }`} onClick={onClick} />
  )
}

const HeaderNavigationItem = ({ icon, iconClass, label, open, showDropdownIcon, onClick, onHover }) => {
  const Icon = icon;

  return (
    <div className={`navigation-item ${showDropdownIcon ? 'desktop' : ''} ${open ? 'hide' : ''}`}
      onClick={onClick}
      onMouseOver={onHover}>
      { icon ? <Icon className={`navigation-icon ${iconClass || ''}`} /> : null }
      <h2>{ label }</h2>
      { showDropdownIcon ? <IoCaretDown className='dropdown-icon' /> : null }
    </div>
  )
}

const NavigationItemHighlight = ({ top }) => {
  return (
    <div className={`navigation-item highlight`}
      style={{ transform: `translateY(${top}px)` }}>
      <h2>photography</h2>
    </div>
  )
}

const HeaderNavigation = ({ location, history }) => {
  const [state, setState] = useState({ 
    open: false,
    highlightPosition: 0
  });

  const activeRoute = Routes.find(x => x.path === location.pathname) || Routes[0];
  const orderedRoutes = Routes.slice().sort((x, y) => {
    const first = x.path === location.pathname ? 1 : 0;
    const second = y.path === location.pathname ? 1 : 0;

    return second - first;
  });

  const onHover = event => {
    if(!event.target.offsetParent) return;

    const offsetTop = event.target.offsetParent.offsetTop;

    if(offsetTop !== state.highlightPosition) {
      setState(state => ({ ...state, highlightPosition: offsetTop }));
    }
  }

  const closeMenu = () => {
    setState({ ...state, open: false, highlightPosition: 0 });
  }
  
  const handleClick = item => {
    if(location.pathname !== item.path) {
      history.push(item.path);
    }
    
    closeMenu();
  }

  return (
    <nav>
      <HeaderNavigationItem 
        label={activeRoute.name}
        onClick={() => setState({ ...state, open: !state.open })}
        open={state.open}
        icon={activeRoute.icon}
        iconClass={activeRoute.iconClass}
        showDropdownIcon={true} />
      <MobileNavigationIcon 
        open={state.open}
        onClick={() => setState({ ...state, open: !state.open })} />
      <ul className={`item-list ${state.open ? 'open': ''}`}>
        {
          orderedRoutes.map(item => (
            <li key={item.id}>
              <HeaderNavigationItem label={item.name}
                onClick={() => handleClick(item)}
                onHover={onHover} />
            </li>
          ))
        }
        <NavigationItemHighlight top={state.highlightPosition} />
      </ul>
    </nav>
  )
}

export default withRouter(HeaderNavigation);