import React, { useLayoutEffect, useState } from 'react';
import './albums.scss';

const Album = ({ albumInfo, active, onClick, onHover }) => {
  const { title } = albumInfo;

  return (
    <figure className={`album ${active ? 'active' : ''}`}
      onClick={onClick}
      onMouseOver={onHover}>
      <h3>{title}</h3>
    </figure>
  )
}

const AlbumHighlighter = ({ offset }) => {
  return <figure className='album highlighter' style={{ transform: `translate(0, ${offset}px)` }}></figure>
}

const AlbumList = ({ albums, activeAlbum, onClick, startGridTransition }) => {
  useLayoutEffect(() => {
    if(!activeAlbum && albums) {
      onClick(albums[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [albums]);

  const [highlighterOffset, setOffset] = useState(0);
  const [handleMouseOut, setHandleMouseOut] = useState(true);

  const onHover = event => {
    const offset = event.target.offsetTop;

    if(event.target.classList.contains('album') && offset !== highlighterOffset) {
      setOffset(offset);
    }
  }

  const onMouseOut = event => {
    if(event.relatedTarget && handleMouseOut && event.relatedTarget.classList.contains('photography')) {
      setTimeout(() => {
        setOffset(albums.findIndex(album => album.id === activeAlbum.id) * 55);
      }, 200);
    }
  }

  const onAlbumClick = (event, album) => {
    startGridTransition();
    setHandleMouseOut(false);
    onClick(album);
    
    setTimeout(() => {
      setHandleMouseOut(true);
      setOffset(event.target.parentElement.offsetTop);
    }, 400);
  }
  
  return (
    <>
      <div className='album-list animate-entry'
        onMouseOut={onMouseOut}>
        {
          albums ? albums.map((album, index) => (
            <Album key={album.id} albumInfo={album}
              active={activeAlbum && activeAlbum.id === album.id}
              onClick={event => onAlbumClick(event, album)}
              onHover={onHover}
              toggleMouseOut={() => setHandleMouseOut(value => !value)} />
          )) : null
        }
        <AlbumHighlighter offset={highlighterOffset} />
      </div>
    </>
  )
}

export default AlbumList;