import React from 'react';
import { useLetterScramble } from '../../../tools/hooks';

export const ScrambledH1 = ({ text, className = '', iterationTime = 100, startDelay = 1000 }) => {
  const title = useLetterScramble(text, iterationTime, startDelay);

  return (
    <h1 className={className}>{title}</h1>
  )
}

export const ScrambledH2 = ({ text, className = '', iterationTime = 100, startDelay = 1000 }) => {
  const title = useLetterScramble(text, iterationTime, startDelay);

  return (
    <h2 className={className}>{title}</h2>
  )
}

export const ScrambledParagraph = ({ text, className = '', iterationTime = 100, startDelay = 1000 }) => {
  const newText = useLetterScramble(text, iterationTime, startDelay);

  return (
    <p className={className}>{newText}</p>
  )
}