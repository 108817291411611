import { nanoid } from '@reduxjs/toolkit';
import { RiCameraLensFill, RiPencilFill } from 'react-icons/ri';

export const Routes = [
  {
    id: nanoid(),
    name: 'projects',
    path: '/projects',
    icon: RiPencilFill,
    iconClass: 'project-icon'
  },
  {
    id: nanoid(),
    name: 'photography',
    path: '/photography',
    icon: RiCameraLensFill,
    iconClass: 'photo-icon'
  },
  {
    id: nanoid(),
    name: 'about',
    path: '/about'
  }
]