import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateBackgroundData } from '../../../store/action-creators/creators';
import { PolygonGenerator } from '../../../tools/polygon-generator';

const mapState = state => ({
  data: state.background.data,
  options: state.background.options
})

const mapDispatch = dispatch => ({
  updateSvg: data => dispatch(updateBackgroundData(data))
})

const PolygonContainerConnector = ({ data, options, location, className = 'poly-container', updateSvg }) => {
  const [animationStep, setAnimationStep] = useState(0);
  const animationStepRef = useRef();
  animationStepRef.current = animationStep;

  useEffect(() => {
    if(data) {
      setAnimationStep(state => state === 3 ? 1 : state + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    updateSvg(
      {
        ...PolygonGenerator.generateData({
          size: { 
            width: window.innerWidth, 
            height: window.innerHeight
          },
          widthNodes: options.widthNodes,
          sway: options.sway,
          color: ''
        }),
        widthNodes: options.widthNodes,
        sway: options.sway,
        classes: 'poly-background'
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const updateRef = ref => {
    if(!ref || !data) return;

    if(!ref.childNodes.length) {
      ref.appendChild(PolygonGenerator.renderData(data));
    } else {
      const svg = ref.childNodes[0];
      const name = `${data.widthNodes}-${data.sway}-${animationStepRef.current}`;
      
      if(svg.attributes['data-id'] !== name) {
        ref.replaceChild(PolygonGenerator.renderData({ ...data, animationStep }), svg);
      }
    }
  }

  return (
    <div className={className} ref={updateRef}></div>
  )
}

const PolygonContainer = connect(mapState, mapDispatch)(PolygonContainerConnector);

export default withRouter(PolygonContainer);