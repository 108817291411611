import React, { useEffect, useState } from 'react';
import './loading-bar.scss';

const LoadingBar = ({ contentLoaded, onAnimationFinish }) => {
  const time = 3000;
  const interval = 200;
  const [state, setState] = useState({
    progress: interval
  })

  useEffect(() => {
    const id = setInterval(() => {
      setState(state => ({ 
        ...state, 
        progress: state.progress < time ? state.progress + interval : state.progress 
      }))
    }, interval);

    return () => clearInterval(id);
  }, []);

  return (
    <div className='loading-container'>
      <div className={`loading-bar ${contentLoaded ? 'finish' : ''}`}
        onAnimationEnd={onAnimationFinish}>
        <span className={`progress`} style={!contentLoaded ? { width: `${(state.progress / time) * 100}%` } : {}}></span>
      </div>
    </div>
  )
}

export default LoadingBar;