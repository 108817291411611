import { select, selectAll, create, range } from 'd3';

const d3 = { select, selectAll, create, range };

function random(min, max) {
  const value = (Math.random() * (max - min)) + min;
  return value;
}

const PointCalculations = {
  pointInRectangle: (m, r) => {
    var AB = PointCalculations.vector(r.A, r.B);
    var AD = PointCalculations.vector(r.A, r.D);
    var AM = PointCalculations.vector(r.A, m);
    var dotAMAB = PointCalculations.dot(AM, AB);
    var dotABAB = PointCalculations.dot(AB, AB);
    var dotAMAD = PointCalculations.dot(AM, AD);
    var dotADAD = PointCalculations.dot(AD, AD);

    return 0 <= dotAMAB && dotAMAB <= dotABAB && 0 <= dotAMAD && dotAMAD <= dotADAD;
  },
  vector: (p1, p2) => {
    return {
      x: (p2.x - p1.x),
      y: (p2.y - p1.y)
    };
  },
  dot: (u, v) => {
    return u.x * v.x + u.y * v.y; 
  }
}

const PolygonHelpers = {
  leftNodes: heights => {
    return heights.map(item => ({
      x: 0, y: item.height,
      anchor: { x: 0, y: item.anchorHeight }
    }))
  },
  rightNodes: (heights, rightBound) => {
    return heights.map(item => ({
      x: rightBound,
      y: item.height,
      anchor: { x: rightBound, y: item.anchorHeight }
    }))
  },
  topNodes: widths => {
    return widths.map(item => ({
      x: item.width, y: 0,
      anchor: { x: item.anchorWidth, y: 0 }
    }))
  },
  bottomNodes: (widths, bottomBound) => {
    return widths.map(item => ({
      x: item.width, y: bottomBound,
      anchor: { x: item.anchorWidth, y: bottomBound }
    }))
  },
  generateHeights: (count, containerHeight, swayPercentage) => {
    const heightSectionSize = containerHeight / (count + 1);
    const anchorNodes = d3.range(count).map(item => (item + 1) * heightSectionSize);
    const sway = heightSectionSize * swayPercentage;
    return d3.range(count).map(item => {
        const anchor = anchorNodes[item];
        const min = anchor - sway;
        const max = anchor + sway;
  
        return { height: random(min, max), anchorHeight: anchor }
      });
  },
  generateWidths: (count, containerWidth, swayPercentage) => {
    const widthSectionSize = containerWidth / (count + 1);
    const anchorNodes = d3.range(count).map(item => (item + 1) * widthSectionSize);
    const sway = widthSectionSize * swayPercentage;
    return d3.range(count).map(item => {
        const anchor = anchorNodes[item];
        const min = anchor - sway;
        const max = anchor + sway;
  
        return { width: random(min, max), anchorWidth: anchor }
      });
  },
  generateInnerNodes: (rows, columns, swayPercentage, containerSize) => {
    const nodes = [];
    const { width, height } = containerSize;
    const widthSectionSize = width / (columns + 1);
    const heightSectionSize = height / (rows + 1);
    const rowAnchors = d3.range(rows).map(item => (item + 1) * heightSectionSize);
    const rowSway = widthSectionSize * swayPercentage;
    const columnAnchors = d3.range(columns).map(item => (item + 1) * widthSectionSize);
    const columnSway = widthSectionSize * swayPercentage;
    
    d3.range(rows).forEach(row => {
      const rowAnchor = rowAnchors[row];
      
      d3.range(columns).forEach(column => {
        const rowMin = rowAnchor - rowSway;
        const rowMax = rowAnchor + rowSway;
        const columnAnchor = columnAnchors[column];
        const colMin = columnAnchor - columnSway;
        const colMax = columnAnchor + columnSway;
        
        nodes.push({
          x: random(colMin, colMax),
          y: random(rowMin, rowMax),
          anchor: { x: columnAnchor, y: rowAnchor },
          firstAnimationNode: { x: random(colMin, colMax), y: random(rowMin, rowMax) },
          secondAnimationNode: { x: random(colMin, colMax), y: random(rowMin, rowMax) }
        })
      })
    })
    
    return nodes;
  },
  generateNodes: (maxHeightNodes, maxWidthNodes, containerSize, sway) => {
    const { width, height } = containerSize;
    const nodes = [
      { x: 0, y: 0, anchor: { x: 0, y: 0 } },
      { x: 0, y: height, anchor: { x: 0, y: height } },
      { x: width, y: 0, anchor: { x: width, y: 0 } },
      { x: width, y: height, anchor: { x: width, y: height } }
    ];
    
    nodes.push(...PolygonHelpers.leftNodes(PolygonHelpers.generateHeights(maxHeightNodes, height, sway)));
    nodes.push(...PolygonHelpers.rightNodes(PolygonHelpers.generateHeights(maxHeightNodes, height, sway), width));
    nodes.push(...PolygonHelpers.topNodes(PolygonHelpers.generateWidths(maxWidthNodes, width, sway)));
    nodes.push(...PolygonHelpers.bottomNodes(PolygonHelpers.generateWidths(maxWidthNodes, width, sway), height));
    nodes.push(...PolygonHelpers.generateInnerNodes(maxHeightNodes, maxWidthNodes, sway, containerSize));
    
    nodes.sort((a,b) => {
      if( a.anchor.x === b.anchor.x) return a.anchor.y-b.anchor.y;
      return a.anchor.x-b.anchor.x;
    });
    
    return nodes;
  },
  generateLinks: (nodes, nodesHorizontal, nodesVertical, containerSize) => {
    const links = [];
    const { width, height } = containerSize;
    const widthIncrement = (width / (nodesHorizontal - 1));
    const heightIncrement = (height / (nodesVertical - 1));
    
    nodes.forEach((node, index) => {
      const anchor = node.anchor;
      const boundingRectangle = {
        A: { x: anchor.x - widthIncrement + 1, y: anchor.y - heightIncrement + 1 }, // top left
        B: { x: anchor.x + widthIncrement + 1, y: anchor.y - heightIncrement + 1 }, // top right
        C: { x: anchor.x + widthIncrement + 1, y: anchor.y + heightIncrement + 1 }, // bottom right
        D: { x: anchor.x - widthIncrement + 1, y: anchor.y + heightIncrement + 1 } // bottom left
      }
      
      let newLinks = nodes.filter(adjacent => {
        const adjacentAnchor = adjacent.anchor;
        const sameNode = adjacentAnchor.x === node.anchor.x && adjacentAnchor.y === node.anchor.y;
        return !sameNode && PointCalculations.pointInRectangle(adjacentAnchor, boundingRectangle);
      }).map(adjacent => {
        return {
          x1: node.x,
          x2: adjacent.x,
          y1: node.y,
          y2: adjacent.y,
          animation: [
            {
              x1: node.firstAnimationNode ? node.firstAnimationNode.x : node.x,
              x2: adjacent.firstAnimationNode ? adjacent.firstAnimationNode.x : adjacent.x,
              y1: node.firstAnimationNode ? node.firstAnimationNode.y : node.y,
              y2: adjacent.firstAnimationNode ? adjacent.firstAnimationNode.y : adjacent.y
            },
            {
              x1: node.secondAnimationNode ? node.secondAnimationNode.x : node.x,
              x2: adjacent.secondAnimationNode ? adjacent.secondAnimationNode.x : adjacent.x,
              y1: node.secondAnimationNode ? node.secondAnimationNode.y : node.y,
              y2: adjacent.secondAnimationNode ? adjacent.secondAnimationNode.y : adjacent.y
            }
          ]
          
          
        }
      });
      
      links.push(...newLinks);
    })
    
    return links;
  },
  // getUniquePoints: points => {
  //   const newPoints = [];
    
  //   points.forEach(point => {
  //     if(!newPoints.some(newPoint => newPoint.x === point.x && newPoint.y === point.y)) {
  //       newPoints.push(point);
  //     }
  //   })
    
  //   return newPoints;
  // },
  // buildTriangles: links => {
  //   const triangles = [];
    
  //   links.forEach(link => {
  //     links.filter(secondLink => {
  //       return (link.x2 === secondLink.x1 && link.y2 === secondLink.y1)
  //     }).forEach(link2 => {
  //       const link3 = links.find(thirdLink => {
  //         return (link2.x2 === thirdLink.x1 && link2.y2 === thirdLink.y1 && thirdLink.x2 === link.x1 && thirdLink.y2 === link.y1) || 
  //           (link2.x2 === thirdLink.x2 && link2.y2 === thirdLink.y2 && thirdLink.x1 === link.x1 && thirdLink.y1 === link.y1);
  //       });
  
  //       if(link3) {
  //         const uniquePoints = PolygonHelpers.getUniquePoints([
  //           { x: link.x1, y: link.y1 },
  //           { x: link.x2, y: link.y2 },
  //           { x: link2.x1, y: link2.y1 },
  //           { x: link2.x2, y: link2.y2 },
  //           { x: link3.x1, y: link3.y1 },
  //           { x: link3.x2, y: link3.y2 }
  //         ])
  //         const lastColor = triangles.length ? triangles[triangles.length - 1].color : 0;
  //         let color = Math.floor(random(1,4));
          
  //         while(lastColor === color) {
  //           color = Math.floor(random(1,4));
  //         }
          
  //         triangles.push({ 
  //           x1: uniquePoints[0].x,
  //           y1: uniquePoints[0].y,
  //           x2: uniquePoints[1].x,
  //           y2: uniquePoints[1].y,
  //           x3: uniquePoints[2].x,
  //           y3: uniquePoints[2].y,
  //           color
  //         })
  //       }
  //     });
  //   })
    
  //   return triangles;
  // }
}

const buildDefs = (svg) => {
  svg.append('defs');
  svg.select('defs')
    .append('mask')
    .attr('id', 'lineMask');

  // setting up mask for the polygons
  svg
    .select('mask')
    .append('g')
    .attr('width', '100%')
    .attr('height', '100%');

  // linear gradient to apply to make the background look less static
  svg.select('defs')
    .append('linearGradient')
    .attr('id', 'lineMaskRectGradient')
    .attr('gradientTransform', 'rotate(65)');
  
  svg.select('linearGradient')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'var(--color-stop-1)');

  svg.select('linearGradient')
    .append('stop')
    .attr('offset', '50%')
    .attr('stop-color', 'var(--color-stop-2)');
  
  svg.select('linearGradient')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'var(--color-stop-3)');

  // radial gradient to fade the edges like vignetting
  svg.select('defs')
    .append('radialGradient')
    .attr('id', 'circularGradient');

  svg.select('radialGradient')
    .append('stop')
    .attr('offset', '50%')
    .attr('stop-color', 'transparent');
  
  svg.select('radialGradient')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#050505');
}

const getStartingPointFromAnimationStep = (key, data, animationStep) => {
  const firstPoint = data[key];
  const secondPoint = data.animation[0][key];
  const thirdPoint = data.animation[1][key];

  switch(animationStep) {
    case 1:
      return firstPoint;
    case 2:
      return secondPoint;
    case 3:
      return thirdPoint;
    default:
      return firstPoint;
  }
}

const getEndingPointFromAnimationStep = (key, data, animationStep) => {
  const firstPoint = data.animation[0][key];
  const secondPoint = data.animation[1][key];
  const thirdPoint = data[key];

  switch(animationStep) {
    case 1:
      return firstPoint;
    case 2:
      return secondPoint;
    case 3:
      return thirdPoint;
    default:
      return firstPoint;
  }
}

const appendAnimations = (lines, animationStep) => {
  lines.append('animate')
    .attr('attributeName', 'x1')
    .attr('from', d => getStartingPointFromAnimationStep('x1', d, animationStep))
    .attr('to', d => getEndingPointFromAnimationStep('x1', d, animationStep))
    .attr('dur', '1s')
    .attr('fill', 'freeze')
    .attr('keyTimes', '0;1')
    .attr('keySplines', '0.83 0 0.17 1')
    .attr('calcMode', 'spline');

  lines.append('animate')
    .attr('attributeName', 'x2')
    .attr('from', d => getStartingPointFromAnimationStep('x2', d, animationStep))
    .attr('to', d => getEndingPointFromAnimationStep('x2', d, animationStep))
    .attr('dur', '1s')
    .attr('fill', 'freeze')
    .attr('keyTimes', '0;1')
    .attr('keySplines', '0.83 0 0.17 1')
    .attr('calcMode', 'spline');

  lines.append('animate')
    .attr('attributeName', 'y1')
    .attr('from', d => getStartingPointFromAnimationStep('y1', d, animationStep))
    .attr('to', d => getEndingPointFromAnimationStep('y1', d, animationStep))
    .attr('dur', '1s')
    .attr('fill', 'freeze')
    .attr('keyTimes', '0;1')
    .attr('keySplines', '0.83 0 0.17 1')
    .attr('calcMode', 'spline');

  lines.append('animate')
    .attr('attributeName', 'y2')
    .attr('from', d => getStartingPointFromAnimationStep('y2', d, animationStep))
    .attr('to', d => getEndingPointFromAnimationStep('y2', d, animationStep))
    .attr('dur', '1s')
    .attr('fill', 'freeze')
    .attr('keyTimes', '0;1')
    .attr('keySplines', '0.83 0 0.17 1')
    .attr('calcMode', 'spline');
}

export const PolygonGenerator = {
  generate: (options) => {
    return PolygonGenerator.renderData(PolygonGenerator.generateData(options));
  },
  generateData: (options) => {
    const { size, widthNodes, sway, color } = options;
    const ratio = size.height / size.width;
    const heightNodes = Math.floor(ratio * widthNodes);

    const nodes = PolygonHelpers.generateNodes(heightNodes - 2, widthNodes - 2, size, sway);
    const links = PolygonHelpers.generateLinks(nodes, widthNodes, heightNodes, size);
    // const triangles = PolygonHelpers.buildTriangles(links);

    return {
      nodes, links, color, id: `${widthNodes}-${sway}`
    }
  },
  renderData: ({ links, color, classes, id, animationStep = 0 }) => {
    const element = d3.create('svg')
      .attr('class', classes ? [color, classes].join(' ') : color)
      .attr('data-id', id);

    buildDefs(element);

    element.select('g')
      .append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', 'white')
      .attr('class', 'block-rect');

    if(animationStep === 0) {
      element.select('g')
        .selectAll('line')
        .data(links)
        .enter()
        .append('line')
        .attr('x1', d => d.x1)
        .attr('x2', d => d.x2)
        .attr('y1', d => d.y1)
        .attr('y2', d => d.y2);
    } else {
      const lines = element.select('g')
        .selectAll('line')
        .data(links)
        .enter()
        .append('line')
        .attr('x1', d => getStartingPointFromAnimationStep('x1', d, animationStep))
        .attr('x2', d => getStartingPointFromAnimationStep('x2', d, animationStep))
        .attr('y1', d => getStartingPointFromAnimationStep('y1', d, animationStep))
        .attr('y2', d => getStartingPointFromAnimationStep('y2', d, animationStep));

      appendAnimations(lines, animationStep);
    }

    // append the rectangle that we apply the mask to
    element.append('rect')
      .attr('class', 'mask-rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('mask', "url('#lineMask')")
      .attr('fill', "url('#lineMaskRectGradient')");

    element.append('rect')
      .attr('width', '130%')
      .attr('height', '130%')
      .attr('x', '-15%')
      .attr('y', '-15%')
      .attr('fill', "url('#circularGradient')");

    const node = element.node();
    
    return node;
  }
}



// const mainContainer = d3.select('main');
// const size = document.getElementsByTagName('main')[0].getBoundingClientRect();
// const widthNodes = 16;
// const heightNodes = Math.floor((9/16) * widthNodes);

// const nodes = generateNodes(heightNodes - 2, widthNodes - 2, .6);

// const { links } = generateLinks(nodes, widthNodes, heightNodes);
// const triangles = buildTriangles(links);

// const thing = d3.select('main')
//   .append('svg')
//   .attr('width', '100%')
//   .attr('height', '100%');

// const things = thing.selectAll('circle').data(nodes);
// thing.selectAll('polygon').data(triangles)
//   .enter()
//   .append('polygon')
//   .attr('points', d => `${d.x1},${d.y1} ${d.x2},${d.y2} ${d.x3},${d.y3}`)
//   .attr('class', d => `purple-${d.color}`)
// //thing.selectAll('circle').data(nodes).enter()
// //  .append('circle')
// //  .merge(things)
// //  .attr('cx', d => d.x)
//  // .attr('cy', d => d.y)
// //  .attr('r', 1)
// //  .attr('fill', 'white')
// //  .attr('opacity', .5);

// thing.selectAll('line').data(links)
//   .enter()
//   .append('line')
//   .attr('x1', d => d.x1)
//   .attr('x2', d => d.x2)
//   .attr('y1', d => d.y1)
//   .attr('y2', d => d.y2);


// function leftNodes(heights) {
//   return heights.map(item => ({
//     x: 0, y: item.height,
//     anchor: { x: 0, y: item.anchorHeight }
//   }))
// }

// function rightNodes(heights) {
//   return heights.map(item => ({
//     x: size.width,
//     y: item.height,
//     anchor: { x: size.width, y: item.anchorHeight }
//   }))
// }

// function topNodes(widths) {
//   return widths.map(item => ({
//     x: item.width, y: 0,
//     anchor: { x: item.anchorWidth, y: 0 }
//   }))
// }

// function bottomNodes(widths) {
//   return widths.map(item => ({
//     x: item.width, y: size.height,
//     anchor: { x: item.anchorWidth, y: size.height }
//   }))
// }

// function generateNodes(maxHeightNodes, maxWidthNodes, sway) {
//   const nodes = [
//     { x: 0, y: 0, anchor: { x: 0, y: 0 } },
//     { x: 0, y: size.height, anchor: { x: 0, y: size.height } },
//     { x: size.width, y: 0, anchor: { x: size.width, y: 0 } },
//     { x: size.width, y: size.height, anchor: { x: size.width, y: size.height } }
//   ];
  
//   nodes.push(...leftNodes(generateHeights(maxHeightNodes, sway)));
//   nodes.push(...rightNodes(generateHeights(maxHeightNodes, sway)));
//   nodes.push(...topNodes(generateWidths(maxWidthNodes, sway)));
//   nodes.push(...bottomNodes(generateWidths(maxWidthNodes, sway)));
//   nodes.push(...generateInnerNodes(maxHeightNodes, maxWidthNodes, sway));
  
//   nodes.sort((a,b) => {
//     if( a.anchor.x == b.anchor.x) return a.anchor.y-b.anchor.y;
//     return a.anchor.x-b.anchor.x;
//   });
  
//   return nodes;
// }

// function generateHeights(count, swayPercentage) {
//   const heightSectionSize = size.height / (count + 1);
//   const anchorNodes = d3.range(count).map(item => (item + 1) * heightSectionSize);
//   const sway = heightSectionSize * swayPercentage;
//   return d3.range(count).map(item => {
//       const anchor = anchorNodes[item];
//       const min = anchor - sway;
//       const max = anchor + sway;

//       return { height: random(min, max), anchorHeight: anchor }
//     });
// }

// function generateWidths(count, swayPercentage) {
//   const widthSectionSize = size.width / (count + 1);
//   const anchorNodes = d3.range(count).map(item => (item + 1) * widthSectionSize);
//   const sway = widthSectionSize * swayPercentage;
//   return d3.range(count).map(item => {
//       const anchor = anchorNodes[item];
//       const min = anchor - sway;
//       const max = anchor + sway;

//       return { width: random(min, max), anchorWidth: anchor }
//     });
// }

// function generateInnerNodes(rows, columns, swayPercentage) {
//   const nodes = [];
//   const widthSectionSize = size.width / (columns + 1);
//   const heightSectionSize = size.height / (rows + 1);
//   const rowAnchors = d3.range(rows).map(item => (item + 1) * heightSectionSize);
//   const rowSway = widthSectionSize * swayPercentage;
//   const columnAnchors = d3.range(columns).map(item => (item + 1) * widthSectionSize);
//   const columnSway = widthSectionSize * swayPercentage;
  
//   d3.range(rows).forEach(row => {
//     const rowAnchor = rowAnchors[row];
    
//     d3.range(columns).forEach(column => {
//       const rowMin = rowAnchor - rowSway;
//       const rowMax = rowAnchor + rowSway;
//       const columnAnchor = columnAnchors[column];
//       const colMin = columnAnchor - columnSway;
//       const colMax = columnAnchor + columnSway;
      
//       nodes.push({
//         x: random(colMin, colMax),
//         y: random(rowMin, rowMax),
//         anchor: { x: columnAnchor, y: rowAnchor }
//       })
//     })
//   })
  
//   return nodes;
// }

// function generateLinks(nodes, nodesHorizontal, nodesVertical) {
//   const links = [];
//   const widthIncrement = (size.width / (nodesHorizontal - 1));
//   const heightIncrement = (size.height / (nodesVertical - 1));
//   const triangles = [];
  
//   nodes.forEach((node, index) => {
//     const anchor = node.anchor;
//     const boundingRectangle = {
//       A: { x: anchor.x - widthIncrement + 1, y: anchor.y - heightIncrement + 1 }, // top left
//       B: { x: anchor.x + widthIncrement + 1, y: anchor.y - heightIncrement + 1 }, // top right
//       C: { x: anchor.x + widthIncrement + 1, y: anchor.y + heightIncrement + 1 }, // bottom right
//       D: { x: anchor.x - widthIncrement + 1, y: anchor.y + heightIncrement + 1 } // bottom left
//     }
    
//     let newLinks = nodes.filter(adjacent => {
//       const adjacentAnchor = adjacent.anchor;
//       const sameNode = adjacentAnchor.x === node.anchor.x && adjacentAnchor.y === node.anchor.y;
//       return !sameNode && pointInRectangle(adjacentAnchor, boundingRectangle);
//     });
    
//     newLinks = newLinks.map(adjacent => {
//       return {
//         x1: node.x,
//         x2: adjacent.x,
//         y1: node.y,
//         y2: adjacent.y
//       }
//     });
    
//     links.push(...newLinks);
//   })
  
//   return {
//     links, triangles
//   };
// }

// function getUniqueLinks(existingLinks, newLinks) {
//   if(!existingLinks.length) {
//     return newLinks;
//   } else {
//     return newLinks.filter(newLink => {
//       return !existingLinks.some(existingLink => {
//         return ((newLink.x1 === existingLink.x1 && newLink.x2 === existingLink.x2) ||
//           (newLink.x1 === existingLink.x2 && newLink.x2 === existingLink.x1)) &&
//           ((newLink.y1 === existingLink.y1 && newLink.y2 === existingLink.y2) ||
//           (newLink.y1 === existingLink.y2 && newLink.y2 === existingLink.y1))
//       })
//     })
//   }
// }

// function calculateDistance(x1, y1, x2, y2) {
//   return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)); 
// }

// function buildTriangles(links) {
//   const triangles = [];
  
//   links.forEach(link => {
//     links.filter(secondLink => {
//       return (link.x2 === secondLink.x1 && link.y2 === secondLink.y1)
//     }).forEach(link2 => {
//       const link3 = links.find(thirdLink => {
//         return (link2.x2 === thirdLink.x1 && link2.y2 === thirdLink.y1 && thirdLink.x2 === link.x1 && thirdLink.y2 === link.y1) || (link2.x2 === thirdLink.x2 && link2.y2 === thirdLink.y2 && thirdLink.x1 === link.x1 && thirdLink.y1 === link.y1);
//       });

//       if(link3) {
//         const uniquePoints = getUniquePoints([
//           { x: link.x1, y: link.y1 },
//           { x: link.x2, y: link.y2 },
//           { x: link2.x1, y: link2.y1 },
//           { x: link2.x2, y: link2.y2 },
//           { x: link3.x1, y: link3.y1 },
//           { x: link3.x2, y: link3.y2 }
//         ])
//         const lastColor = triangles.length ? triangles[triangles.length - 1].color : 0;
//         let color = Math.floor(random(1,4));
        
//         while(lastColor === color) {
//           color = Math.floor(random(1,4));
//         }
        
//         triangles.push({ 
//           x1: uniquePoints[0].x,
//           y1: uniquePoints[0].y,
//           x2: uniquePoints[1].x,
//           y2: uniquePoints[1].y,
//           x3: uniquePoints[2].x,
//           y3: uniquePoints[2].y,
//           color
//         })
//       }
//     });
//   })
  
//   return triangles;
// }

// function getUniquePoints(points) {
//   const newPoints = [];
//   console.log(points)
  
//   points.forEach(point => {
//     if(!newPoints.some(newPoint => newPoint.x === point.x && newPoint.y === point.y)) {
//       newPoints.push(point);
//     }
//   })
  
//   return newPoints;
// }



// function pointInRectangle(m, r) {
//     var AB = vector(r.A, r.B);
//     var AD = vector(r.A, r.D);
//     var AM = vector(r.A, m);
//     var dotAMAB = dot(AM, AB);
//     var dotABAB = dot(AB, AB);
//     var dotAMAD = dot(AM, AD);
//     var dotADAD = dot(AD, AD);

//     return 0 <= dotAMAB && dotAMAB <= dotABAB && 0 <= dotAMAD && dotAMAD <= dotADAD;
// }

// function vector(p1, p2) {
//     return {
//             x: (p2.x - p1.x),
//             y: (p2.y - p1.y)
//     };
// }

// function dot(u, v) {
//     return u.x * v.x + u.y * v.y; 
// }

// function areLinksEqual(link1, link2) {
//   return calculateDistance(link1.x1, link1.y1, link1.x2, link1.y2) ===
//     calculateDistance(link2.x1, link2.y1, link2.x2, link2.y2);
// }

// function setup(baseNodes, sway) {
//   const mainContainer = d3.select('main');
//   const size = document.getElementsByTagName('main')[0].getBoundingClientRect();
//   const widthNodes = baseNodes;
//   const heightNodes = Math.floor((9/16) * widthNodes);

//   const nodes = generateNodes(heightNodes - 2, widthNodes - 2, sway);

//   const { links } = generateLinks(nodes, widthNodes, heightNodes);
//   const triangles = buildTriangles(links);

//   const thing = d3.select('svg');
//   thing.selectAll('*').remove();

//   thing.selectAll('polygon').data(triangles)
//     .enter()
//     .append('polygon')
//     .attr('points', d => `${d.x1},${d.y1} ${d.x2},${d.y2} ${d.x3},${d.y3}`)
//     .attr('class', d => `purple-${d.color}`)

//   thing.selectAll('line').data(links)
//     .enter()
//     .append('line')
//     .attr('x1', d => d.x1)
//     .attr('x2', d => d.x2)
//     .attr('y1', d => d.y1)
//     .attr('y2', d => d.y2);
// }